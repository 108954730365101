module.exports = [{
      plugin: require('../../.yarn/__virtual__/gatsby-plugin-styled-components-virtual-337c1ac758/0/cache/gatsby-plugin-styled-components-npm-6.10.0-3fe3237f8b-256c7b2891.zip/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../.yarn/__virtual__/gatsby-plugin-manifest-virtual-3820316cfb/0/cache/gatsby-plugin-manifest-npm-5.10.0-80908bca4d-ad7b5a4f91.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/assets/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"53aa06cf17e4239d0dba6ffd09854e02"},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../../.yarn/unplugged/gatsby-virtual-1c6b3f3859/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
